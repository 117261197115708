<template>
  <div>
    <section-header title="Top 10 Favourites"></section-header>
    <v-row>
      <v-col></v-col>
      <v-col>
        <v-list-item
          v-for="song in favSongs"
          :key="song.title"
          @click.stop="playSong(song)"
        >
          <v-list-item-avatar>
            <v-btn fab dark x-small color="primary darken-2">
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ song.title }}</v-list-item-title>
            <!-- <v-list-item-subtitle></v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-dialog
      v-model="showDialog"
      :width="$vuetify.breakpoint.mdAndUp ? 840 : 420"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark>
          <span>{{ currentSong.title }}</span>
          <span class="subtitle-2 grey--text pl-4"
            >℗ {{ currentSong.channel }}</span
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="ma-0 pa-0 text-center">
          <iframe
            :width="$vuetify.breakpoint.mdAndUp ? 800 : 400"
            :height="$vuetify.breakpoint.mdAndUp ? 500 : 250"
            :src="`https://www.youtube.com/embed/${currentSong.link}?autoplay=1`"
            allow="fullscreen;"
            style="border: none"
          >
          </iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    showDialog: false,
    currentSong: {},
    favSongs: [
      {
        title: 'Kusum Kusum Roddur',
        link: 'lDxQrlPMH3I',
        channel: 'Cozmik Harmony'
      },
      {
        title: 'Dujoner Hriday Theke',
        link: 'MUJ55RhlNy8',
        channel: 'Cozmik Harmony'
      },
      {
        title: 'Ami Nei Bhebe',
        link: 'QYuLvnqt1s0',
        channel: 'Cozmik Harmony'
      },
      {
        title: 'Tomaro Janala Ki',
        link: 'FJDTFW3U5ng',
        channel: 'Shemaroo Entertainment Ltd.'
      },
      {
        title: 'Kotha Chhilo',
        link: 'yjCmUf4ip_w',
        channel: 'Cozmik Harmony'
      },
      {
        title: 'Kibhabe Kothay Jiban Juray',
        link: 'hLOh-cAgTsc',
        channel: 'Shemaroo Entertainment Ltd.'
      },
      {
        title: 'Chilte Rod',
        link: '986VR0ys_18',
        channel: 'Cozmik Harmony'
      },
      {
        title: 'Sarata Sokal',
        link: 'atu2DIlD070',
        channel: 'Cozmik Harmony'
      },
      {
        title: 'Tumi Nei',
        link: 'UuAcI6EF74c',
        channel: 'Cozmik Harmony'
      },
      {
        title: 'Srabon Ghera',
        link: '2rXfQV09zCU',
        channel: 'Cozmik Harmony'
      }
    ]
  }),
  methods: {
    playSong (song) {
      this.currentSong = song
      this.showDialog = true
    },
    closeDialog () {
      this.showDialog = false
      setTimeout(() => {
        this.currentSong = {}
      }, 250)
    }
  },
  components: {
    SectionHeader: () => import('@/components/reusable/SectionHeader')
  }
}
</script>
